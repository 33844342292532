































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "name",
          text: root.$tc("panel.event.add.alert.templateName"),
          sortable: false,
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      error: false as boolean | number,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("registration-form-template", {
          params: { sortBy, sortDesc, page, itemsPerPage },
        })
        .then(({ data: { registrationFormTemplates, total } }) => {
          state.empty = false;
          state.items = registrationFormTemplates.map(
            (registrationFormTemplate: any, index: number) => ({
              id: index,
              name: "",
              ...registrationFormTemplate,
            })
          );
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const copyForm = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`registration-form-template/${id}`)
        .then(({ data: { registrationFormTemplate } }) => {
          state.loading = true;

          const name = prompt(
            root.$tc(
              "panel.globalTools.registrationForm.form.list.enterNewTemplateName"
            ),
            `${registrationFormTemplate.name} (kopia)`
          );

          if (name) {
            axiosInstance
              .post("registration-form-template", {
                name,
                groups: registrationFormTemplate.groups,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("alert.cantCopyTemplate"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.formTemplate409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`registration-form-template/${item.id}`)
        .then(() => {
          item.deleteDialog = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.deleteSuccess"),
          });
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { state, fetchData, copyForm, deleteItem };
  },
});
